// Overriding bootstrap
.navbar-brand{
	z-index: 101;
}
.navbar .right.menu{
	z-index: 10;
}
.row {
	flex-wrap: nowrap;
}
.hero-image{
	.intro-container{
		margin-top: 0;
	}
}
.navbar-brand.brand{
	padding: 5px;
}
p{
	word-wrap: break-word;
}
.site-logo img{
	height: 32px;
}
a.aff-link:not(.btn){
	color: #4183c4;
}
.ui.secondary.menu .item {
	padding: 0.5em
}

.footer.bg-dark .ui.link.list a,h4{
    color: white;
}

